import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CRS, icon } from 'leaflet'
import { Map, ImageOverlay, Marker } from 'react-leaflet'
import {
  ALERT_TYPES,
  ZOOM_LEVELS,
  DEFAULT_PLAN_ZOOM_LEVEL,
  THRESHOLD_ALERT_TYPES,
  USER_PERMISSIONS,
} from '../constants'
import { hasPermission } from '../utils/hasPermission'

import podAlert from '../assets/floor-plan/pod-action-alert.svg'
import podInfoAlert from '../assets/floor-plan/pod-info-alert.svg'
import podUnassigned from '../assets/floor-plan/pod-unassigned.svg'
import podOffline from '../assets/floor-plan/pod-offline.svg'
import podOnline from '../assets/floor-plan/pod-online.svg'

import flowOnline from '../assets/floor-plan/flow-monitor-online.svg'
import flowOffline from '../assets/floor-plan/flow-monitor-offline.svg'
import flowAlert from '../assets/floor-plan/flow-monitor-action-alert.svg'
import flowInfoAlert from '../assets/floor-plan/flow-monitor-info-alert.svg'

// import leakPodOnline from '../assets/floor-plan/leak-pod.svg'
// import leakPodOffline from '../assets/floor-plan/leak-pod-offline.svg'
// import leakPodInfoAlert from '../assets/floor-plan/leak-pod-info-alert.svg'
// import leakPodAlert from '../assets/floor-plan/leak-pod-action-alert.svg'

class TinyFloorPlan extends Component {
  static propTypes = {
    floor: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  render() {
    const {
      floor: {
        planFileName,
        planZoomLevel = DEFAULT_PLAN_ZOOM_LEVEL,
        floorplanHeight = 0,
        floorplanWidth = 0,
      },
      location: {
        id,
        podPillarId,
        flowMonitorPillarId,
        activeAlerts,
        coordinates,
        alertActive,
        alertType,
        threshold,
      },
    } = this.props

    const bounds = [
      [0, 0],
      [floorplanHeight, floorplanWidth],
    ]
    let iconUrl =
      flowMonitorPillarId && hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
        ? flowOnline
        : podPillarId
        ? podOnline
        : podUnassigned

    if (alertActive) {
      if (alertType === ALERT_TYPES.THRESHOLD) {
        let thresholdAlertType

        if (!!activeAlerts) {
          const actionAlerts = activeAlerts.filter(
            x => x.thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE
          )
          thresholdAlertType =
            actionAlerts.length > 0
              ? actionAlerts[actionAlerts.length - 1].thresholdAlertType
              : activeAlerts[activeAlerts.length - 1].thresholdAlertType
        } else {
          thresholdAlertType = threshold.thresholdAlertType
        }

        iconUrl =
          flowMonitorPillarId &&
          hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
          thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE
            ? flowAlert
            : flowMonitorPillarId &&
              hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
            ? flowInfoAlert
            : thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE
            ? podAlert
            : podInfoAlert
      } else {
        iconUrl =
          flowMonitorPillarId &&
          hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
            ? flowOffline
            : podOffline
      }
    }

    const podIcon = icon({
      iconUrl,
      iconSize: [32, 32],
      iconAnchor: [15, 15],
      popupAnchor: [0, -15],
    })

    return (
      <div className="TinyFloorPlan mb3">
        <Map
          crs={CRS.Simple}
          maxBounds={bounds}
          zoom={planZoomLevel}
          center={coordinates}
          minZoom={ZOOM_LEVELS.MIN}
          maxZoom={ZOOM_LEVELS.MAX}
          className="ba b--gray br2 vh-25 black-80 lh-copy bg-moon-gray"
          attributionControl={false}
          zoomControl={false}
          doubleClickZoom={false}
        >
          <ImageOverlay url={planFileName} bounds={bounds} />
          <Marker position={coordinates} key={id} icon={podIcon} />
        </Map>
      </div>
    )
  }
}

export default TinyFloorPlan
