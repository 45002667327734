import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllInventory from '../AllInventory'
import CreateGateway from '../CreateGateway'
import Gateway from '../Gateway'
import EditGateway from '../EditGateway'
import CreatePod from '../CreatePod'
import EditPod from '../EditPod'
import Pod from '../Pod'
import CreateSIMCard from '../CreateSIMCard'
import EditSIMCard from '../EditSIMCard'
import SIMCard from '../SIMCard'
import CreatePart from '../CreatePart'
import EditPart from '../EditPart'
import Part from '../Part'
import CreatePurchase from '../CreatePurchase'
import EditPurchase from '../EditPurchase'
import Purchase from '../Purchase'
import EditFlowMonitor from '../EditFlowMonitor'
import CreateFlowMonitor from '../CreateFlowMonitor'
import FlowMonitor from '../FlowMonitor'
import EditWaterMeter from '../EditWaterMeter'
import CreateWaterMeter from '../CreateWaterMeter'
import EditWaterValve from '../EditWaterValve'
import CreateWaterValve from '../CreateWaterValve'
import CreateGatewaySupply from '../CreateGatewaySupply'
import EditGatewaySupply from '../EditGatewaySupply'
import GatewaySupply from '../GatewaySupply'
import AssetLocation from '../AssetLocation'
import CreateAssetLocation from '../CreateAssetLocation'
import EditAssetLocation from '../EditAssetLocation'
import InventoryForecasts from '../InventoryForecasts'
import MultiplePods from '../MultiplePods'
import OTA from '../OTA'
import OTADetail from '../OTADetail'
import PodRefurbResponse from '../PodRefurbResponse'
import PageWidth from '../PageWidth'
import { dateTimeFormatter } from '../../utils/date'

class InventoryPage extends Component {
  static propTypes = {
    deleteAssetLocation: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    getAllAssetLocations: PropTypes.func.isRequired,
    getPodAssignmentHistory: PropTypes.func,
    getPodEditHistory: PropTypes.func,
    getFlowMonitorEditHistory: PropTypes.func,
    getFlowMonitorAssignmentHistory: PropTypes.func,
    getAssetLocation: PropTypes.func.isRequired,
    currentAssetLocation: PropTypes.object.isRequired,
    assetLocationsMeta: PropTypes.object.isRequired,
    gateways: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentGateway: PropTypes.object.isRequired,
    getAllGateways: PropTypes.func.isRequired,
    getGatewayUptimeData: PropTypes.func.isRequired,
    currentGatewayConnectedPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getGatewayConnectedPods: PropTypes.func.isRequired,
    getGateway: PropTypes.func.isRequired,
    restartGateway: PropTypes.func.isRequired,
    deleteGateway: PropTypes.func.isRequired,
    simCards: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentSIMCard: PropTypes.object.isRequired,
    getAllSIMCards: PropTypes.func.isRequired,
    getSIMCard: PropTypes.func.isRequired,
    deleteSIMCard: PropTypes.func.isRequired,
    simCardsMeta: PropTypes.object.isRequired,
    parts: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPart: PropTypes.object.isRequired,
    getAllParts: PropTypes.func.isRequired,
    getPart: PropTypes.func.isRequired,
    deletePart: PropTypes.func.isRequired,
    partsMeta: PropTypes.object.isRequired,
    purchases: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPurchase: PropTypes.object.isRequired,
    getAllPurchases: PropTypes.func.isRequired,
    getPurchase: PropTypes.func.isRequired,
    deletePurchase: PropTypes.func.isRequired,
    purchasesMeta: PropTypes.object.isRequired,
    flowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentFlowMonitor: PropTypes.object.isRequired,
    getAllFlowMonitors: PropTypes.func.isRequired,
    getFlowMonitor: PropTypes.func.isRequired,
    deleteFlowMonitor: PropTypes.func.isRequired,
    updateFlowMonitor: PropTypes.func.isRequired,
    flowMonitorsMeta: PropTypes.object.isRequired,
    getAllAvailablePods: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFlowMonitorData: PropTypes.func.isRequired,
    flowMonitorData: PropTypes.object.isRequired,
    setWaterValveFields: PropTypes.func.isRequired,
    setWaterMeterFields: PropTypes.func.isRequired,
    allAvailableWaterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableWaterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableWaterValves: PropTypes.func.isRequired,
    getAllAvailableWaterMeters: PropTypes.func.isRequired,
    waterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllWaterMeters: PropTypes.func.isRequired,
    currentWaterMeter: PropTypes.object.isRequired,
    getWaterMeter: PropTypes.func.isRequired,
    deleteWaterMeter: PropTypes.func.isRequired,
    waterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllWaterValves: PropTypes.func.isRequired,
    currentWaterValve: PropTypes.object.isRequired,
    getWaterValve: PropTypes.func.isRequired,
    deleteWaterValve: PropTypes.func.isRequired,
    gatewaySupplies: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentGatewaySupply: PropTypes.object.isRequired,
    getAllGatewaySupplies: PropTypes.func.isRequired,
    getGatewaySupply: PropTypes.func.isRequired,
    deleteGatewaySupply: PropTypes.func.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    gatewaysMeta: PropTypes.object.isRequired,
    podsMeta: PropTypes.object.isRequired,
    pods: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPod: PropTypes.object.isRequired,
    currentPodChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPodChartColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentPodAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    currentPodEditHistory: PropTypes.arrayOf(PropTypes.object),
    currentFlowMonitorAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    getValveStatusHistory: PropTypes.func.isRequired,
    currentValveStatusHistory: PropTypes.arrayOf(PropTypes.object),
    currentFlowMonitorEditHistory: PropTypes.arrayOf(PropTypes.object),
    isSnapshotLoading: PropTypes.bool.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getAllPods: PropTypes.func.isRequired,
    getPod: PropTypes.func.isRequired,
    getPodChartData: PropTypes.func.isRequired,
    deletePod: PropTypes.func.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
    isPodLoading: PropTypes.bool.isRequired,
    isAssetLocationLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    isSIMCardLoading: PropTypes.bool.isRequired,
    isPartLoading: PropTypes.bool.isRequired,
    isPurchaseLoading: PropTypes.bool.isRequired,
    isFlowMonitorLoading: PropTypes.bool.isRequired,
    isGatewaySupplyLoading: PropTypes.bool.isRequired,
    isCommentLoading: PropTypes.bool.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    getPodMetadata: PropTypes.func.isRequired,
    podMetadata: PropTypes.object.isRequired,
    getAllSites: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    firmwareVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
    getAllFirmwareVersions: PropTypes.func.isRequired,
    otaLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentOtaLog: PropTypes.object.isRequired,
    getAllOTALogs: PropTypes.func.isRequired,
    getOTALog: PropTypes.func.isRequired,
    setOTAPodIds: PropTypes.func.isRequired,
    otaFormValues: PropTypes.object,
    currentGatewayUptimeData: PropTypes.object.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    currentComment: PropTypes.object,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    batchUpdatePods: PropTypes.func.isRequired,
    getGatewayAssignmentHistory: PropTypes.func.isRequired,
    currentGatewayAssignmentHistory: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    uploadPodsCSV: PropTypes.func.isRequired,
    uploadCalibrationCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
    getGatewayRestartHistory: PropTypes.func.isRequired,
    gatewayRestartHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    getPodAssets: PropTypes.func.isRequired,
    podImages: PropTypes.arrayOf(PropTypes.object).isRequired,
    getPodWorkflowResponses: PropTypes.func.isRequired,
    workflowResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getPodWorkflowResponse: PropTypes.func.isRequired,
    currentWorkflowResponse: PropTypes.object.isRequired,
    getCalibrationHistory: PropTypes.func.isRequired,
    calibrationHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCalibrationHistoryLoading: PropTypes.bool.isRequired,
    getAllDeployments: PropTypes.func.isRequired,
    getInventoryProjections: PropTypes.func.isRequired,
    counts: PropTypes.object.isRequired,
    projections: PropTypes.object.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    createInventoryProposal: PropTypes.func.isRequired,
    updateInventoryProposal: PropTypes.func.isRequired,
    getInventoryProposal: PropTypes.func.isRequired,
    deleteInventoryProposal: PropTypes.func.isRequired,
    convertInventoryProposal: PropTypes.func.isRequired,
    proposal: PropTypes.object.isRequired,
  }

  render() {
    const {
      match: { url },
      assetLocations,
      getAllAssetLocations,
      deleteAssetLocation,
      getAssetLocation,
      currentAssetLocation,
      assetLocationsMeta,
      gateways,
      currentGatewayUptimeData,
      currentGateway,
      getAllGateways,
      getGatewayUptimeData,
      currentGatewayConnectedPods,
      getGatewayConnectedPods,
      getGateway,
      deleteGateway,
      currentSIMCard,
      getAllSIMCards,
      getSIMCard,
      deleteSIMCard,
      simCards,
      simCardsMeta,
      currentPart,
      getAllParts,
      getPart,
      deletePart,
      parts,
      partsMeta,
      currentPurchase,
      getAllPurchases,
      getPurchase,
      deletePurchase,
      purchases,
      purchasesMeta,
      currentFlowMonitor,
      getAllFlowMonitors,
      getFlowMonitor,
      deleteFlowMonitor,
      flowMonitors,
      flowMonitorsMeta,
      getAllAvailablePods,
      allAvailablePods,
      getFlowMonitorData,
      flowMonitorData,
      updateFlowMonitor,
      setWaterValveFields,
      setWaterMeterFields,
      allAvailableWaterValves,
      allAvailableWaterMeters,
      getAllAvailableWaterValves,
      getAllAvailableWaterMeters,
      waterMeters,
      getAllWaterMeters,
      currentWaterMeter,
      getWaterMeter,
      deleteWaterMeter,
      waterValves,
      getAllWaterValves,
      currentWaterValve,
      getWaterValve,
      deleteWaterValve,
      currentGatewaySupply,
      getAllGatewaySupplies,
      getGatewaySupply,
      deleteGatewaySupply,
      gatewaySupplies,
      isSnapshotLoading,
      snapshotData,
      getLocationSnapshot,
      pods,
      currentPod,
      currentPodChartData,
      currentPodChartColumns,
      getAllPods,
      getPod,
      getPodChartData,
      getPodAssignmentHistory,
      getPodEditHistory,
      getFlowMonitorAssignmentHistory,
      getValveStatusHistory,
      currentValveStatusHistory,
      getFlowMonitorEditHistory,
      deletePod,
      gatewaysMeta,
      podsMeta,
      currentPodAssignmentHistory,
      currentPodEditHistory,
      currentFlowMonitorAssignmentHistory,
      currentFlowMonitorEditHistory,
      isGatewayLoading,
      isPodLoading,
      isAssetLocationLoading,
      isHistoryLoading,
      isCommentLoading,
      isChartDataLoading,
      windowWidth,
      getPodMetadata,
      podMetadata,
      getAllSites,
      sites,
      firmwareVersions,
      getAllFirmwareVersions,
      otaLogs,
      currentOtaLog,
      getAllOTALogs,
      getOTALog,
      setOTAPodIds,
      otaFormValues,
      updateQueryParams,
      getAllComments,
      deleteComment,
      editComment,
      createComment,
      currentComment,
      getAllTags,
      comments,
      tags,
      restartGateway,
      isSIMCardLoading,
      isPartLoading,
      isPurchaseLoading,
      isFlowMonitorLoading,
      isGatewaySupplyLoading,
      batchUpdatePods,
      getGatewayAssignmentHistory,
      currentGatewayAssignmentHistory,
      csvUploadResults,
      uploadPodsCSV,
      uploadCalibrationCSV,
      getGatewayRestartHistory,
      gatewayRestartHistory,
      getPodAssets,
      podImages,
      getPodWorkflowResponses,
      workflowResponses,
      getPodWorkflowResponse,
      currentWorkflowResponse,
      getCalibrationHistory,
      calibrationHistory,
      isCalibrationHistoryLoading,
      counts,
      projections,
      getAllDeployments,
      getInventoryProjections,
      createInventoryProposal,
      updateInventoryProposal,
      getInventoryProposal,
      deleteInventoryProposal,
      convertInventoryProposal,
      isDeploymentLoading,
      proposal,
    } = this.props

    return (
      <MainContent>
        <div className="InventoryPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/all`}
                render={props => (
                  <AllInventory
                    getAllAssetLocations={getAllAssetLocations}
                    deleteAssetLocation={deleteAssetLocation}
                    getAllGateways={getAllGateways}
                    deleteGateway={deleteGateway}
                    getAllSIMCards={getAllSIMCards}
                    deleteSIMCard={deleteSIMCard}
                    getAllParts={getAllParts}
                    parts={parts}
                    partsMeta={partsMeta}
                    deletePart={deletePart}
                    getAllPurchases={getAllPurchases}
                    purchases={purchases}
                    purchasesMeta={purchasesMeta}
                    deletePurchase={deletePurchase}
                    getAllFlowMonitors={getAllFlowMonitors}
                    flowMonitors={flowMonitors}
                    flowMonitorsMeta={flowMonitorsMeta}
                    deleteFlowMonitor={deleteFlowMonitor}
                    getAllWaterMeters={getAllWaterMeters}
                    waterMeters={waterMeters}
                    deleteWaterMeter={deleteWaterMeter}
                    getAllWaterValves={getAllWaterValves}
                    waterValves={waterValves}
                    deleteWaterValve={deleteWaterValve}
                    getAllGatewaySupplies={getAllGatewaySupplies}
                    gatewaySupplies={gatewaySupplies}
                    deleteGatewaySupply={deleteGatewaySupply}
                    getAllPods={getAllPods}
                    deletePod={deletePod}
                    getPodMetadata={getPodMetadata}
                    getAllSites={getAllSites}
                    isSnapshotLoading={isSnapshotLoading}
                    snapshotData={snapshotData}
                    getLocationSnapshot={getLocationSnapshot}
                    pods={pods}
                    gateways={gateways}
                    simCards={simCards}
                    sites={sites}
                    assetLocations={assetLocations}
                    podMetadata={podMetadata}
                    gatewaysMeta={gatewaysMeta}
                    simCardsMeta={simCardsMeta}
                    podsMeta={podsMeta}
                    assetLocationsMeta={assetLocationsMeta}
                    windowWidth={windowWidth}
                    isGatewayLoading={isGatewayLoading}
                    isPodLoading={isPodLoading}
                    isAssetLocationLoading={isAssetLocationLoading}
                    isHistoryLoading={isHistoryLoading}
                    isSIMCardLoading={isSIMCardLoading}
                    isPartLoading={isPartLoading}
                    isPurchaseLoading={isPurchaseLoading}
                    isFlowMonitorLoading={isFlowMonitorLoading}
                    isGatewaySupplyLoading={isGatewaySupplyLoading}
                    updateQueryParams={updateQueryParams}
                    batchUpdatePods={batchUpdatePods}
                    uploadPodsCSV={uploadPodsCSV}
                    uploadCalibrationCSV={uploadCalibrationCSV}
                    csvUploadResults={csvUploadResults}
                    {...props}
                  />
                )}
              />
              <Route
                path={`${url}/gateways/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Network Gateway</title>
                    </Helmet>
                    <CreateGateway
                      getAllTags={getAllTags}
                      tags={tags}
                      assetLocations={assetLocations}
                      getAllAssetLocations={getAllAssetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateways/:pillarId/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentGateway.gatewayName
                          ? `Edit ${currentGateway.gatewayName}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditGateway
                      getAllTags={getAllTags}
                      tags={tags}
                      gateway={currentGateway}
                      getGateway={getGateway}
                      assetLocations={assetLocations}
                      getAllAssetLocations={getAllAssetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateways/:pillarId`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentGateway.gatewayName || 'Loading...'}
                      </title>
                    </Helmet>
                    <Gateway
                      gateway={currentGateway}
                      getGateway={getGateway}
                      getGatewayAssignmentHistory={getGatewayAssignmentHistory}
                      gatewayAssignmentHistory={currentGatewayAssignmentHistory}
                      restartGateway={restartGateway}
                      isGatewayLoading={isGatewayLoading}
                      isCommentLoading={isCommentLoading}
                      isHistoryLoading={isHistoryLoading}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      tags={tags}
                      comments={comments}
                      gatewayUptimeData={currentGatewayUptimeData}
                      getGatewayUptimeData={getGatewayUptimeData}
                      gatewayConnectedPods={currentGatewayConnectedPods}
                      getGatewayConnectedPods={getGatewayConnectedPods}
                      updateQueryParams={updateQueryParams}
                      isChartDataLoading={isChartDataLoading}
                      getGatewayRestartHistory={getGatewayRestartHistory}
                      gatewayRestartHistory={gatewayRestartHistory}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/sim-cards/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentSIMCard.name
                          ? `Edit ${currentSIMCard.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditSIMCard
                      simCard={currentSIMCard}
                      getSIMCard={getSIMCard}
                      getAllFlowMonitors={getAllFlowMonitors}
                      flowMonitors={flowMonitors}
                      getAllGateways={getAllGateways}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      isSIMCardLoading={isSIMCardLoading}
                      gateways={gateways}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/sim-cards/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create SIM Card Entry</title>
                    </Helmet>
                    <CreateSIMCard
                      getAllFlowMonitors={getAllFlowMonitors}
                      flowMonitors={flowMonitors}
                      getAllGateways={getAllGateways}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      gateways={gateways}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/sim-cards/:id`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentSIMCard.simNumber || 'Loading...'}</title>
                    </Helmet>
                    <SIMCard
                      simCard={currentSIMCard}
                      getSIMCard={getSIMCard}
                      isSIMCardLoading={isSIMCardLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/parts/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentPart.name
                          ? `Edit ${currentPart.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditPart
                      part={currentPart}
                      getPart={getPart}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      isPartLoading={isPartLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/parts/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Part Entry</title>
                    </Helmet>
                    <CreatePart
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/parts/:id`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentPart.name || 'Loading...'}</title>
                    </Helmet>
                    <Part
                      part={currentPart}
                      getPart={getPart}
                      isPartLoading={isPartLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/purchases/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentPurchase.name
                          ? `Edit ${currentPurchase.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditPurchase
                      purchase={currentPurchase}
                      getPurchase={getPurchase}
                      parts={parts}
                      getAllParts={getAllParts}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      isPurchaseLoading={isPurchaseLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/purchases/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Purchase Entry</title>
                    </Helmet>
                    <CreatePurchase
                      parts={parts}
                      getAllParts={getAllParts}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/purchases/:id`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentPurchase.name || 'Loading...'}</title>
                    </Helmet>
                    <Purchase
                      purchase={currentPurchase}
                      getPurchase={getPurchase}
                      isPurchaseLoading={isPurchaseLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/flow-monitors/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentFlowMonitor.pillarId
                          ? `Edit ${currentFlowMonitor.pillarId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditFlowMonitor
                      allAvailableWaterValves={allAvailableWaterValves}
                      allAvailableWaterMeters={allAvailableWaterMeters}
                      getAllAvailableWaterValves={getAllAvailableWaterValves}
                      getAllAvailableWaterMeters={getAllAvailableWaterMeters}
                      setWaterValveFields={setWaterValveFields}
                      setWaterMeterFields={setWaterMeterFields}
                      flowMonitor={currentFlowMonitor}
                      getFlowMonitor={getFlowMonitor}
                      getAllAvailablePods={getAllAvailablePods}
                      getAllAssetLocations={getAllAssetLocations}
                      allAvailablePods={allAvailablePods}
                      assetLocations={assetLocations}
                      isFlowMonitorLoading={isFlowMonitorLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/flow-monitors/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Water Monitor</title>
                    </Helmet>
                    <CreateFlowMonitor
                      allAvailableWaterMeters={allAvailableWaterMeters}
                      allAvailableWaterValves={allAvailableWaterValves}
                      getAllAvailableWaterValves={getAllAvailableWaterValves}
                      getAllAvailableWaterMeters={getAllAvailableWaterMeters}
                      setWaterValveFields={setWaterValveFields}
                      setWaterMeterFields={setWaterMeterFields}
                      getAllAvailablePods={getAllAvailablePods}
                      getAllAssetLocations={getAllAssetLocations}
                      allAvailablePods={allAvailablePods}
                      assetLocations={assetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/flow-monitors/:id`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentFlowMonitor.pillarId || 'Loading...'}
                      </title>
                    </Helmet>
                    <FlowMonitor
                      updateFlowMonitor={updateFlowMonitor}
                      currentFlowId={currentFlowMonitor.pillarId}
                      updateQueryParams={updateQueryParams}
                      isLoading={isChartDataLoading}
                      getFlowMonitorData={getFlowMonitorData}
                      flowMonitorData={flowMonitorData}
                      flowMonitor={currentFlowMonitor}
                      getFlowMonitor={getFlowMonitor}
                      assignmentHistory={currentFlowMonitorAssignmentHistory}
                      getAssignmentHistory={getFlowMonitorAssignmentHistory}
                      valveStatusHistory={currentValveStatusHistory}
                      getValveStatusHistory={getValveStatusHistory}
                      editHistory={currentFlowMonitorEditHistory}
                      getEditHistory={getFlowMonitorEditHistory}
                      isHistoryLoading={isHistoryLoading}
                      isFlowMonitorLoading={isFlowMonitorLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/water-meters/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentWaterMeter.meterSerialId
                          ? `Edit ${currentWaterMeter.meterSerialId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditWaterMeter
                      waterMeter={currentWaterMeter}
                      getWaterMeter={getWaterMeter}
                      isWaterMeterLoading={isFlowMonitorLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/water-meters/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Water Meter</title>
                    </Helmet>
                    <CreateWaterMeter {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/water-valves/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentWaterValve.valveSerialId
                          ? `Edit ${currentWaterValve.valveSerialId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditWaterValve
                      waterValve={currentWaterValve}
                      getWaterValve={getWaterValve}
                      isWaterValveLoading={isFlowMonitorLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/water-valves/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Water Valve</title>
                    </Helmet>
                    <CreateWaterValve {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateway-supplies/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentGatewaySupply.name
                          ? `Edit ${currentGatewaySupply.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditGatewaySupply
                      gatewaySupply={currentGatewaySupply}
                      getGatewaySupply={getGatewaySupply}
                      isGatewaySupplyLoading={isGatewaySupplyLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateway-supplies/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Network Gateway Supply Entry</title>
                    </Helmet>
                    <CreateGatewaySupply {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/gateway-supplies/:id`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentGatewaySupply.name || 'Loading...'}</title>
                    </Helmet>
                    <GatewaySupply
                      gatewaySupply={currentGatewaySupply}
                      getGatewaySupply={getGatewaySupply}
                      isGatewaySupplyLoading={isGatewaySupplyLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/pods/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Smart Pod</title>
                    </Helmet>
                    <CreatePod
                      {...props}
                      getAllTags={getAllTags}
                      tags={tags}
                      assetLocations={assetLocations}
                      getAllAssetLocations={getAllAssetLocations}
                      getPodMetadata={getPodMetadata}
                      podMetadata={podMetadata}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/pods/compare`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Compare Smart Pods</title>
                    </Helmet>
                    <MultiplePods
                      updateQueryParams={updateQueryParams}
                      isChartDataLoading={isChartDataLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/pods/:pillarId/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentPod.pillarId
                          ? `Edit ${currentPod.pillarId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditPod
                      getAllTags={getAllTags}
                      tags={tags}
                      pod={currentPod}
                      getPod={getPod}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      getPodMetadata={getPodMetadata}
                      podMetadata={podMetadata}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/pods/:podPillarId/refurb-responses/:workflowResponseSlug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentWorkflowResponse.addedAt
                          ? `${dateTimeFormatter(
                              currentWorkflowResponse.addedAt,
                              true
                            )} Smart Pod Refurb`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <PodRefurbResponse
                      getPodWorkflowResponse={getPodWorkflowResponse}
                      workflowResponse={currentWorkflowResponse}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/pods/:pillarId`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentPod.pillarId || 'Loading...'}</title>
                    </Helmet>
                    <Pod
                      pod={currentPod}
                      currentPodChartData={currentPodChartData}
                      currentPodChartColumns={currentPodChartColumns}
                      getPod={getPod}
                      getPodChartData={getPodChartData}
                      isPodLoading={isPodLoading}
                      isHistoryLoading={isHistoryLoading}
                      isCommentLoading={isCommentLoading}
                      isChartDataLoading={isChartDataLoading}
                      updateQueryParams={updateQueryParams}
                      assignmentHistory={currentPodAssignmentHistory}
                      getAssignmentHistory={getPodAssignmentHistory}
                      editHistory={currentPodEditHistory}
                      getEditHistory={getPodEditHistory}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      currentComment={currentComment}
                      comments={comments}
                      tags={tags}
                      getPodAssets={getPodAssets}
                      podImages={podImages}
                      getPodWorkflowResponses={getPodWorkflowResponses}
                      workflowResponses={workflowResponses}
                      getCalibrationHistory={getCalibrationHistory}
                      calibrationHistory={calibrationHistory}
                      isCalibrationHistoryLoading={isCalibrationHistoryLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/ota/:slug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>OTA Detail</title>
                    </Helmet>
                    <OTADetail
                      currentOtaLog={currentOtaLog}
                      getOTALog={getOTALog}
                      otaFormValues={otaFormValues}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/ota`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>OTA Inventory</title>
                    </Helmet>
                    <OTA
                      firmwareVersions={firmwareVersions}
                      sites={sites}
                      otaLogs={otaLogs}
                      getAllFirmwareVersions={getAllFirmwareVersions}
                      getAllPods={getAllPods}
                      getAllOTALogs={getAllOTALogs}
                      getAllSites={getAllSites}
                      setOTAPodIds={setOTAPodIds}
                      otaFormValues={otaFormValues}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/asset-locations/create`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Asset Location</title>
                    </Helmet>
                    <CreateAssetLocation {...props} />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/asset-locations/:pillarId/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentAssetLocation.pillarId
                          ? `Edit ${currentAssetLocation.pillarId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditAssetLocation
                      assetLocation={currentAssetLocation}
                      getAssetLocation={getAssetLocation}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/asset-locations/:pillarId`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentAssetLocation.pillarId
                          ? `Edit ${currentAssetLocation.pillarId}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <AssetLocation
                      assetLocation={currentAssetLocation}
                      getAssetLocation={getAssetLocation}
                      {...props}
                    />
                  </Fragment>
                )}
              />

              <Route
                path={`${url}/forecasts`}
                render={props => (
                  <>
                    <Helmet>
                      <title>Inventory Forecasts</title>
                    </Helmet>
                    <InventoryForecasts
                      counts={counts}
                      projections={projections}
                      podMetadata={podMetadata}
                      getAllDeployments={getAllDeployments}
                      getInventoryProjections={getInventoryProjections}
                      createInventoryProposal={createInventoryProposal}
                      updateInventoryProposal={updateInventoryProposal}
                      getInventoryProposal={getInventoryProposal}
                      deleteInventoryProposal={deleteInventoryProposal}
                      convertInventoryProposal={convertInventoryProposal}
                      getPodMetadata={getPodMetadata}
                      isLoading={isDeploymentLoading}
                      proposal={proposal}
                      {...props}
                    />
                  </>
                )}
              />
            </Switch>
          </PageWidth>
        </div>
      </MainContent>
    )
  }
}

export default InventoryPage
